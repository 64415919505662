import React, { useMemo, useState } from 'react';
import { Box, Paper, Typography, Grid } from '@mui/material';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
  PieChart, Pie, Cell, LineChart, Line, ResponsiveContainer, Sector
} from 'recharts';
import dayjs from 'dayjs';
import InventoryIcon from '@mui/icons-material/Inventory';

interface StatisticsProps {
  assetData: any[];
}

const COLORS = {
  status: {
    active: '#4CAF50',      // Bright Green
    inactive: '#78909C',    // Blue Grey
    'under maintenance': '#2196F3',  // Bright Blue
    'under_maintenance': '#2196F3',  // Bright Blue (alternative format)
    assigned: '#FF9800',    // Orange
    damaged: '#E53935',     // Red
    replaced: '#7E57C2'     // Purple
  },
  category: {
    'IT': '#00ACC1',        // Cyan
    'Non-IT': '#FFB300',    // Amber
    'Other': '#7CB342',     // Light Green
    'Furniture': '#EC407A', // Pink
    'Equipment': '#5C6BC0', // Indigo
    'Uncategorized': '#FF7043' // Deep Orange
  }
};

const Statistics: React.FC<StatisticsProps> = ({ assetData }) => {
  const [activeStatusIndex, setActiveStatusIndex] = useState(0);
  const [activeCategoryIndex, setActiveCategoryIndex] = useState(0);

  const statistics = useMemo(() => {
    // Asset Categories Distribution
    const categoryDistribution = assetData.reduce((acc: any, asset: any) => {
      const category = asset.asset_category || 'Uncategorized';
      acc[category] = (acc[category] || 0) + 1;
      return acc;
    }, {});

    // Updated Asset Status Distribution
    const statusDistribution = assetData.reduce((acc: any, asset: any) => {
      const status = (asset.status || '').toLowerCase().trim();
      acc[status] = (acc[status] || 0) + 1;
      return acc;
    }, {});

    // Ensure all statuses are represented
    const allStatuses = ['active', 'inactive', 'under maintenance', 'assigned', 'damaged', 'replaced'];
    allStatuses.forEach(status => {
      if (!statusDistribution[status]) {
        statusDistribution[status] = 0;
      }
    });

    // Monthly Asset Acquisition
    const monthlyAcquisition = assetData.reduce((acc: any, asset: any) => {
      const month = dayjs(asset.purchase_date).format('MMM YYYY');
      acc[month] = (acc[month] || 0) + 1;
      return acc;
    }, {});

    // Asset Value by Category
    const valueByCategory = assetData.reduce((acc: any, asset: any) => {
      const category = asset.asset_category || 'Uncategorized';
      acc[category] = (acc[category] || 0) + Number(asset.purchase_cost);
      return acc;
    }, {});

    return {
      categoryDistribution: Object.entries(categoryDistribution).map(([name, value]) => ({ name, value })),
      statusDistribution: Object.entries(statusDistribution).map(([name, value]) => ({
        name: name.charAt(0).toUpperCase() + name.slice(1),
        value,
        color: COLORS.status[name as keyof typeof COLORS.status] || '#999'
      })),
      monthlyAcquisition: Object.entries(monthlyAcquisition)
        .sort((a, b) => dayjs(a[0], 'MMM YYYY').valueOf() - dayjs(b[0], 'MMM YYYY').valueOf())
        .map(([name, value]) => ({ name, value })),
      valueByCategory: Object.entries(valueByCategory).map(([name, value]) => ({ name, value }))
    };
  }, [assetData]);

  const renderActiveShape = (props: any) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, 
            fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333" fontSize={12}>
          {`${payload.name}`}
        </text>
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#666" fontSize={12}>
          {`${value} (${(percent * 100).toFixed(1)}%)`}
        </text>
      </g>
    );
  };

  const renderLegend = (data: any[], colors: any) => (
    <Box sx={{ 
      display: "flex", 
      flexDirection: "column", 
      gap: 1.5,
      justifyContent: "center",
      minWidth: '150px',
      ml: 2
    }}>
      {data.map((item, index) => {
        // Handle both formats of status names (with space or underscore)
        const colorKey = item.name.toLowerCase().replace(/ /g, '_');
        const colorKey2 = item.name.toLowerCase().replace(/_/g, ' ');
        
        return (
          <Box key={item.name} display="flex" alignItems="center">
            <Box
              sx={{
                width: 12,
                height: 12,
                backgroundColor: typeof colors === 'object' 
                  ? colors[colorKey] || colors[colorKey2] || colors[item.name] || Object.values(colors)[index % Object.values(colors).length]
                  : colors[index % colors.length],
                marginRight: 1,
                borderRadius: '50%'
              }}
            />
            <Typography variant="body2" color="text.secondary">
              {item.name} ({item.value})
            </Typography>
          </Box>
        );
      })}
    </Box>
  );

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" sx={{ mb: 4, color: 'text.primary' }}>
        Asset Statistics & Analytics
      </Typography>

      <Grid container spacing={3}>
        {/* Asset Status Distribution */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3, height: '450px' }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <Typography variant="h6" color="text.primary">Asset Status Distribution</Typography>
              <Box>
                <Typography variant="h4" component="div" fontWeight="bold">
                  {assetData.length}
                </Typography>
                <Box display="flex" alignItems="center" justifyContent="flex-end">
                  <InventoryIcon color="primary" fontSize="small" sx={{ mr: 0.5 }} />
                  <Typography variant="body2" color="primary">Total Assets</Typography>
                </Box>
              </Box>
            </Box>
            <Box display="flex" sx={{ height: 350 }}>
              {renderLegend(statistics.statusDistribution, COLORS.status)}
              <Box sx={{ flex: 1 }}>
                <ResponsiveContainer>
                  <PieChart>
                    <Pie
                      activeIndex={activeStatusIndex}
                      activeShape={renderActiveShape}
                      data={statistics.statusDistribution}
                      cx="50%"
                      cy="50%"
                      innerRadius={90}
                      outerRadius={120}
                      dataKey="value"
                      onMouseEnter={(_, index) => setActiveStatusIndex(index)}
                      paddingAngle={5}
                    >
                      {statistics.statusDistribution.map((entry) => {
                        const colorKey = entry.name.toLowerCase().replace(/ /g, '_');
                        const colorKey2 = entry.name.toLowerCase().replace(/_/g, ' ');
                        return (
                          <Cell 
                            key={entry.name} 
                            fill={COLORS.status[colorKey as keyof typeof COLORS.status] || COLORS.status[colorKey2 as keyof typeof COLORS.status] || '#999'}
                          />
                        );
                      })}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </Box>
            </Box>
          </Paper>
        </Grid>

        {/* Asset Categories Distribution */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3, height: '450px' }}>
            <Typography variant="h6" sx={{ mb: 2 }} color="text.primary">
              Asset Categories Distribution
            </Typography>
            <Box display="flex" sx={{ height: 350 }}>
              {renderLegend(statistics.categoryDistribution, COLORS.category)}
              <Box sx={{ flex: 1 }}>
                <ResponsiveContainer>
                  <PieChart>
                    <Pie
                      activeIndex={activeCategoryIndex}
                      activeShape={renderActiveShape}
                      data={statistics.categoryDistribution}
                      cx="50%"
                      cy="50%"
                      innerRadius={90}
                      outerRadius={120}
                      dataKey="value"
                      onMouseEnter={(_, index) => setActiveCategoryIndex(index)}
                      paddingAngle={5}
                    >
                      {statistics.categoryDistribution.map((entry, index) => {
                        const colorKey = entry.name.replace(/ /g, '_');
                        return (
                          <Cell 
                            key={entry.name} 
                            fill={COLORS.category[entry.name as keyof typeof COLORS.category] || COLORS.category[colorKey as keyof typeof COLORS.category] || Object.values(COLORS.category)[index % Object.values(COLORS.category).length]}
                          />
                        );
                      })}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </Box>
            </Box>
          </Paper>
        </Grid>

        {/* Monthly Asset Acquisition Trend */}
        <Grid item xs={12}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" sx={{ mb: 2 }} color="text.primary">
              Monthly Asset Acquisition Trend
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={statistics.monthlyAcquisition}>
                <CartesianGrid strokeDasharray="3 3" stroke="#eee" />
                <XAxis dataKey="name" stroke="#666" />
                <YAxis stroke="#666" />
                <Tooltip 
                  contentStyle={{ backgroundColor: '#fff', borderRadius: '8px' }}
                  itemStyle={{ color: '#666' }}
                />
                <Legend />
                <Line 
                  type="monotone" 
                  dataKey="value" 
                  stroke="#2196F3" 
                  strokeWidth={2}
                  dot={{ fill: '#2196F3' }}
                />
              </LineChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Statistics; 