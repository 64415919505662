import React, { useState, useMemo } from 'react';
import {
  IconButton,
  Modal,
  Box,
  Typography,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  styled,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Select,
  MenuItem
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import CloseIcon from '@mui/icons-material/Close';
import AddAsset from './AddAsset';
import jsPDF from 'jspdf';
import dayjs from 'dayjs';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot
} from '@mui/lab';
import { Divider, Chip } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { convertToAppliance, fetchAssetHistory } from '../../../api/endpoints/APIEndpoints';
import AssetHistoryTimeline from './AssetHistoryTimeline';
import { toast } from 'react-toastify';
import HistoryIcon from '@mui/icons-material/History';
import AssetStatisticsCards from './AssetStatisticsCards';
import NewModal from '../../common/newModal';
import DevicesIcon from '@mui/icons-material/Devices';
import { FaPrint } from 'react-icons/fa';
import { PiBarcode } from "react-icons/pi";
import Barcode from '../../../components/app/Barcode';
import { printBarcode } from '../../../utils/common';
import LinkIcon from '@mui/icons-material/Link';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import AccountTreeIcon from '@mui/icons-material/AccountTree';

interface AssetDashboardProps {
  assetData: any[];
}

const TableWrapper = styled(Box)({
  height: 'calc(100vh - 280px)', // Same height as AssetWorth
  overflow: 'auto'
});

const AssetDashboard: React.FC<AssetDashboardProps> = ({ assetData }) => {
  const [selectedAsset, setSelectedAsset] = useState<any | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [showAllMaintenance, setShowAllMaintenance] = useState(false);
  const INITIAL_RECORDS_TO_SHOW = 3; // Number of recent records to show initially
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const [assetHistory, setAssetHistory] = useState<any[]>([]);
  const [isMoveToApplianceDialogOpen, setIsMoveToApplianceDialogOpen] = useState(false);
  const [statusFilter, setStatusFilter] = useState<string>('all');
  const [isBarcodeModalOpen, setIsBarcodeModalOpen] = useState(false);

  const getStatusStyle = (status: string) => {
    switch (status.toLowerCase()) {
      case "active":
        return "text-green-800 bg-green-100";
      case "inactive":
        return "text-red-800 bg-red-100";
      case "maintenance":
        return "text-yellow-800 bg-yellow-100";
      default:
        return "text-gray-800 bg-gray-100";
    }
  };

  const handleAssetClick = (asset: any) => {
    setSelectedAsset(asset);
    fetchAssetHistory.post({
      asset_id: asset.asset_id
    }).then((res: any) => {
      console.log(res)
    })
    setIsModalOpen(true);
  };

  const handleEditClick = (asset: any, e: React.MouseEvent) => {
    e.stopPropagation();
    setSelectedAsset(asset);
    setIsEditModalOpen(true);
  };

  const handleDeleteClick = (asset: any, e: React.MouseEvent) => {
    e.stopPropagation();
    setSelectedAsset(asset);
    setIsDeleteDialogOpen(true);
  };

  const handlePrintAsset = (asset: any, e: React.MouseEvent) => {
    e.stopPropagation();
    const doc = new jsPDF();

    doc.setFontSize(16);
    doc.text(`Asset Details: ${asset.asset_name}`, 14, 15);

    (doc as any).autoTable({
      head: [['Field', 'Value']],
      body: [
        ['Asset Name', asset.asset_name],
        ['Status', asset.status],
        ['Location', asset.location],
        ['Manufacturer', asset.manufacturer],
        ['Serial Number', asset.serial_number],
        ['Model', asset.model],
        ['Purchase Date', dayjs(asset.purchase_date).format('YYYY-MM-DD')],
        ['Purchase Cost', asset.purchase_cost],
        ['Depreciation Rate', `${asset.depreciation_rate}%`],
        ['Department', asset.department],
        ['Reminder Frequency', `${asset.reminder_frequency} days`]
      ],
      startY: 25,
      styles: { fontSize: 8 },
      headStyles: { fillColor: [66, 66, 66] },
    });

    doc.save(`asset_${asset.asset_name}.pdf`);
  };

  const handleDelete = () => {
    // Implement delete functionality
    console.log('Deleting asset:', selectedAsset);
    setIsDeleteDialogOpen(false);
    setSelectedAsset(null);
  };

  const calculateDepreciationValue = (asset: any) => {
    const purchaseDate = dayjs(asset.purchase_date);
    const today = dayjs();
    const yearsDiff = today.diff(purchaseDate, 'year', true);
    const originalValue = parseFloat(asset.purchase_cost);
    const depreciationRate = parseFloat(asset.depreciation_rate) / 100;

    const currentValue = originalValue * Math.pow(1 - depreciationRate, yearsDiff);
    return currentValue.toFixed(2);
  };

  const handleViewHistory = async (asset: any, e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      const response = await fetchAssetHistory.post({
        asset_id: asset.asset_id
      });
      setAssetHistory(response.data.rows);
      setIsHistoryModalOpen(true);
    } catch (error) {
      console.error('Error fetching asset history:', error);
      toast.error('Failed to fetch asset history');
    }
  };

  const handleMoveToAppliance = async (asset: any, e: React.MouseEvent) => {
    e.stopPropagation();
    setSelectedAsset(asset);
    setIsMoveToApplianceDialogOpen(true);
  };

  const confirmMoveToAppliance = async () => {
    try {
      // Add your API endpoint here to update the asset
      // await updateAsset.post({ 
      //   asset_id: selectedAsset.asset_id, 
      //   is_appliance: true 
      // });
      
      await convertToAppliance.post({
        asset_id: selectedAsset.asset_id,
      })
      toast.success('Asset successfully moved to appliance');
      setIsMoveToApplianceDialogOpen(false);
      // Refresh your data here
    } catch (error) {
      console.error('Error moving asset to appliance:', error);
      toast.error('Failed to move asset to appliance');
    }
  };

  const getUniqueStatuses = () => {
    const statuses = assetData?.map(asset => asset.status.toLowerCase()) || [];
    return ['all', ...Array.from(new Set(statuses))];
  };

  const filteredAssets = useMemo(() => {
    if (statusFilter === 'all') return assetData;
    return assetData?.filter(asset => 
      asset.status.toLowerCase() === statusFilter.toLowerCase()
    );
  }, [assetData, statusFilter]);

  const handlePrintDetails = () => {
    if (!selectedAsset) return;

    const doc = new jsPDF({
      unit: 'pt',
      format: 'a4',
    });
    // Helper function for currency formatting
    const formatAmount = (amount: number | string) => {
      const numAmount = typeof amount === 'string' ? parseFloat(amount) : amount;
      return `${numAmount.toLocaleString('en-IN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}`;
    };

    // Title
    doc.setFontSize(20);
    doc.setTextColor(51, 65, 85); // text-slate-700
    doc.text('Asset Details', 40, 40);

    let yOffset = 80;

    // Asset Relationships Section
    if (selectedAsset.parent_asset || selectedAsset.child_assets?.length > 0) {
      yOffset = (doc as any).lastAutoTable.finalY + 30;
      
      // Add new page if not enough space
      if (yOffset > doc.internal.pageSize.height - 200) {
        doc.addPage();
        yOffset = 40;
      }

      doc.setFontSize(14);
      doc.text('Asset Relationships', 40, yOffset);
      yOffset += 25;

      // Parent Asset
      if (selectedAsset.parent_asset) {
        doc.setFontSize(10);
        doc.text('Parent Asset', 40, yOffset);
        yOffset += 25;

        doc.text(`Name: ${selectedAsset.parent_asset.asset_name}`, 40, yOffset);
        yOffset += 25;
        doc.text(`Status: ${selectedAsset.parent_asset.status}`, 40, yOffset);
        yOffset += 25;
      }

      // Child Assets
      if (selectedAsset.child_assets?.length > 0) {
        doc.setFontSize(10);
        doc.text('Child Assets', 40, yOffset);
        yOffset += 25;

        selectedAsset.child_assets.forEach((childAsset: any) => {
          doc.text(`- ${childAsset.asset_name}`, 40, yOffset);
          doc.text(`  Status: ${childAsset.status}`, 40, yOffset + 25);
          yOffset += 25;
        });
      }
    }

    // Basic Information Section
    doc.setFontSize(14);
    doc.text('Basic Information', 40, yOffset);
    yOffset += 25;

    const basicInfo = [
      ['Asset Name', selectedAsset.asset_name],
      ['Status', selectedAsset.status],
      ['Location', selectedAsset.location],
      ['Department', selectedAsset.AssetDepartments?.map((dept: any) => 
        dept?.AssociatedDepartment.department_name).join(', ') || '-']
    ];

    (doc as any).autoTable({
      startY: yOffset,
      body: basicInfo,
      theme: 'plain',
      styles: { 
        fontSize: 10,
        cellPadding: 5,
        textColor: [75, 85, 99]
      },
      columnStyles: {
        0: { fontStyle: 'bold', cellWidth: 150 },
        1: { cellWidth: 'auto' }
      },
      margin: { left: 40 }
    });

    yOffset = (doc as any).lastAutoTable.finalY + 30;

    // Technical Details Section
    doc.setFontSize(14);
    doc.text('Technical Details', 40, yOffset);
    yOffset += 25;

    const technicalInfo = [
      ['Manufacturer', selectedAsset.manufacturer],
      ['Serial Number', selectedAsset.serial_number],
      ['Model', selectedAsset.model],
      ['Reminder Frequency', `${selectedAsset.reminder_frequency} days`]
    ];

    (doc as any).autoTable({
      startY: yOffset,
      body: technicalInfo,
      theme: 'plain',
      styles: { 
        fontSize: 10,
        cellPadding: 5,
        textColor: [75, 85, 99]
      },
      columnStyles: {
        0: { fontStyle: 'bold', cellWidth: 150 },
        1: { cellWidth: 'auto' }
      },
      margin: { left: 40 }
    });

    yOffset = (doc as any).lastAutoTable.finalY + 30;

    // Purchase Information Section
    doc.setFontSize(14);
    doc.text('Purchase Information', 40, yOffset);
    yOffset += 25;

    const purchaseInfo = [
      ['Purchase Date', dayjs(selectedAsset.purchase_date).format('DD/MM/YYYY')],
      ['Purchase Cost', formatAmount(selectedAsset.purchase_cost)],
      ['Current Value', formatAmount(calculateDepreciationValue(selectedAsset))],
      ['Depreciation Rate', `${selectedAsset.depreciation_rate}%`]
    ];

    (doc as any).autoTable({
      startY: yOffset,
      body: purchaseInfo,
      theme: 'plain',
      styles: { 
        fontSize: 10,
        cellPadding: 5,
        textColor: [75, 85, 99],
        font: 'Helvetica'
      },
      columnStyles: {
        0: { fontStyle: 'bold', cellWidth: 150 },
        1: { cellWidth: 'auto', halign: 'right' }
      },
      margin: { left: 40 }
    });

    // Add Maintenance History if exists
    if (selectedAsset.maintenance_history?.maintenance_history?.length > 0) {
      yOffset = (doc as any).lastAutoTable.finalY + 30;
      
      // Add new page if not enough space
      if (yOffset > doc.internal.pageSize.height - 200) {
        doc.addPage();
        yOffset = 40;
      }

      doc.setFontSize(14);
      doc.text('Maintenance History', 40, yOffset);
      yOffset += 25;

      const maintenanceHistory = selectedAsset.maintenance_history.maintenance_history.map((maintenance: any) => [
        dayjs(maintenance.date).format('DD/MM/YYYY'),
        maintenance.status || 'Completed',
        formatAmount(maintenance.amount),
        maintenance.notes || '-'
      ]);

      (doc as any).autoTable({
        startY: yOffset,
        head: [['Date', 'Status', 'Amount', 'Notes']],
        body: maintenanceHistory,
        theme: 'striped',
        headStyles: { 
          fillColor: [59, 130, 246],
          textColor: 255,
          fontSize: 10
        },
        styles: { 
          fontSize: 9,
          cellPadding: 5,
          textColor: [75, 85, 99]
        },
        margin: { left: 40 }
      });
    }

    doc.save(`${selectedAsset.asset_name}_details.pdf`);
  };

  const handleBarcodeView = (asset: any, e: React.MouseEvent) => {
    e.stopPropagation();
    setSelectedAsset(asset);
    setIsBarcodeModalOpen(true);
  };

  const closeBarcodeModal = () => {
    setIsBarcodeModalOpen(false);
    setSelectedAsset(null);
  };

  return (
    <div className="flex flex-col h-full">
      {/* Stats Cards */}
      <AssetStatisticsCards assetData={assetData} />
      
      {/* Add Filter Section */}
      <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
          Filter by Status:
        </Typography>
        <Box sx={{ minWidth: 200 }}>
          <Select
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
            size="small"
            fullWidth
          >
            {getUniqueStatuses().map((status) => (
              <MenuItem 
                key={status} 
                value={status}
                sx={{ textTransform: 'capitalize' }}
              >
                {status === 'all' ? 'All Statuses' : status}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>

      {/* Table Section */}
      <TableWrapper>
        <TableContainer component={Paper} elevation={2} sx={{ height: '100%' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 600, bgcolor: 'grey.100' }}>Asset Name</TableCell>
                <TableCell sx={{ fontWeight: 600, bgcolor: 'grey.100' }}>Appliance (Yes/No)</TableCell>
                <TableCell sx={{ fontWeight: 600, bgcolor: 'grey.100' }}>Status</TableCell>
                <TableCell sx={{ fontWeight: 600, bgcolor: 'grey.100' }}>Location</TableCell>
                <TableCell sx={{ fontWeight: 600, bgcolor: 'grey.100' }}>Purchase Date</TableCell>
                <TableCell sx={{ fontWeight: 600, bgcolor: 'grey.100' }}>Current Value</TableCell>
                <TableCell sx={{ fontWeight: 600, bgcolor: 'grey.100' }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredAssets?.map((asset: any) => (
                <TableRow
                  key={asset.asset_id}
                  hover
                  onClick={() => handleAssetClick(asset)}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell>{asset.asset_name}</TableCell>
                  <TableCell>{asset.is_appliance ? "Yes" : "No"}</TableCell>
                  <TableCell>
                    <span className={`px-2 py-1 text-xs font-semibold rounded-full ${getStatusStyle(asset.status)}`}>
                      {asset.status.toUpperCase()}
                    </span>
                  </TableCell>
                  <TableCell>{asset.location}</TableCell>
                  <TableCell>{dayjs(asset.purchase_date).format('YYYY-MM-DD')}</TableCell>
                  <TableCell>₹{calculateDepreciationValue(asset)}</TableCell>
                  <TableCell>
                    <div className="flex gap-2">
                      {!asset.is_appliance && (
                        <Tooltip title="Move to Appliance">
                          <IconButton 
                            onClick={(e) => handleMoveToAppliance(asset, e)} 
                            size="small"
                            sx={{
                              '&:hover': {
                                color: 'primary.main',
                                backgroundColor: 'primary.lighter',
                              }
                            }}
                          >
                            <DevicesIcon className="w-5 h-5" />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title="History">
                        <IconButton onClick={(e) => handleViewHistory(asset, e)} size="small">
                          <HistoryIcon className="w-5 h-5" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit">
                        <IconButton onClick={(e) => handleEditClick(asset, e)} size="small">
                          <EditIcon className="w-5 h-5" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton onClick={(e) => handleDeleteClick(asset, e)} size="small">
                          <DeleteIcon className="w-5 h-5" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="View Barcode">
                        <IconButton 
                          onClick={(e) => handleBarcodeView(asset, e)} 
                          size="small"
                          sx={{
                            '&:hover': {
                              color: 'primary.main',
                              backgroundColor: 'primary.lighter',
                            }
                          }}
                        >
                          <PiBarcode className="w-5 h-5" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TableWrapper>

      {/* Asset Details Modal */}
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="asset-details-modal"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          maxWidth: '800px',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 2,
          maxHeight: '90vh',
          overflowY: 'auto',
        }}>
          {selectedAsset && (
            <>
              {/* Header */}
              <Box sx={{ 
                bgcolor: '#f8fafc',
                p: 2,
                borderBottom: '1px solid #e2e8f0',
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
                <Typography variant="h6" component="h2" sx={{ color: '#334155', fontWeight: 600 }}>
                  Asset Details
                </Typography>
                <div className="flex items-center gap-2">
                  <Tooltip title="Print Details">
                    <IconButton onClick={handlePrintDetails}>
                      <PrintIcon />
                    </IconButton>
                  </Tooltip>
                  <IconButton onClick={() => setIsModalOpen(false)}>
                    <CloseIcon />
                  </IconButton>
                </div>
              </Box>

              {/* Content */}
              <Box sx={{ p: 3 }} id="asset-details-content">
                {/* Asset Relationships Section */}
                {(selectedAsset.parent_asset_id) && (
                  <div className="mb-6">
                    <Typography variant="subtitle1" sx={{ color: '#334155', fontWeight: 600, mb: 2 }}>
                      Asset Relationships
                    </Typography>
                    <div className="bg-gray-50 p-4 rounded-lg space-y-4">
                      {/* Parent Asset */}
                      {selectedAsset.parent_asset_id && (
                        <div>
                          <Typography variant="subtitle2" sx={{ color: '#475569', mb: 1 }}>
                            <AccountTreeIcon sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom' }} />
                            Parent Asset: {selectedAsset.parent_asset_id}
                          </Typography>
                          
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {/* Basic Information Section */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                  <div className="space-y-4">
                    <Typography variant="subtitle1" sx={{ color: '#334155', fontWeight: 600, mb: 2 }}>
                      Basic Information
                    </Typography>
                    <div className="bg-gray-50 p-4 rounded-lg space-y-3 text-sm">
                      <p className="flex justify-between">
                        <span className="text-gray-600">Asset Name:</span>
                        <span className="font-medium">{selectedAsset.asset_name}</span>
                      </p>
                      <p className="flex justify-between">
                        <span className="text-gray-600">Status:</span>
                        <Chip 
                          label={selectedAsset.status}
                          color={selectedAsset.status === 'active' ? 'success' : 'warning'}
                          size="small"
                        />
                      </p>
                      <p className="flex justify-between">
                        <span className="text-gray-600">Location Category:</span>
                        <span className="font-medium">{selectedAsset.location_category}</span>
                      </p>
                      <p className="flex justify-between">
                        <span className="text-gray-600">Location:</span>
                        <span className="font-medium">{selectedAsset.location}</span>
                      </p>
                      <p className="flex justify-between">
                        <span className="text-gray-600">Asset Category:</span>
                        <span className="font-medium">{selectedAsset.asset_category}</span>
                      </p>
                      <p className="flex justify-between">
                        <span className="text-gray-600">Asset Type:</span>
                        <span className="font-medium">{selectedAsset.asset_type}</span>
                      </p>
                      <p className="flex justify-between">
                        <span className="text-gray-600">Ownership Type:</span>
                        <span className="font-medium">{selectedAsset.ownership_type}</span>
                      </p>
                      <p className="flex justify-between">
                        <span className="text-gray-600">Department:</span>
                        <span className="font-medium">
                          {selectedAsset.AssetDepartments?.map((dept: any) => dept?.AssociatedDepartment.department_name).join(', ')}
                        </span>
                      </p>
                      <p className="flex justify-between">
                        <span className="text-gray-600">Depreciation Rate:</span>
                        <span className="font-medium">{selectedAsset.depreciation_rate}%</span>
                      </p>
                    </div>
                  </div>

                  <div className="space-y-4">
                    <Typography variant="subtitle1" sx={{ color: '#334155', fontWeight: 600, mb: 2 }}>
                      Technical Details
                    </Typography>
                    <div className="bg-gray-50 p-4 rounded-lg space-y-3 text-sm">
                      <p className="flex justify-between">
                        <span className="text-gray-600">Manufacturer:</span>
                        <span className="font-medium">{selectedAsset.manufacturer}</span>
                      </p>
                      <p className="flex justify-between">
                        <span className="text-gray-600">Serial Number:</span>
                        <span className="font-medium">{selectedAsset.serial_number}</span>
                      </p>
                      <p className="flex justify-between">
                        <span className="text-gray-600">Model:</span>
                        <span className="font-medium">{selectedAsset.model}</span>
                      </p>
                      <p className="flex justify-between">
                        <span className="text-gray-600">Reminder Frequency:</span>
                        <span className="font-medium">{selectedAsset.reminder_frequency} days</span>
                      </p>
                    </div>
                  </div>
                </div>

                {/* Purchase Information */}
                <div className="mb-6">
                  <Typography variant="subtitle1" sx={{ color: '#334155', fontWeight: 600, mb: 2 }}>
                    Purchase Information
                  </Typography>
                  <div className="bg-gray-50 p-4 rounded-lg text-sm">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div className="space-y-3">
                        <p className="flex justify-between">
                          <span className="text-gray-600">Purchase Date:</span>
                          <span className="font-medium">{dayjs(selectedAsset.purchase_date).format('DD/MM/YYYY')}</span>
                        </p>
                        <p className="flex justify-between">
                          <span className="text-gray-600">Purchase Cost:</span>
                          <span className="font-medium">₹{selectedAsset.purchase_cost}</span>
                        </p>
                        <p className="flex justify-between">
                          <span className="text-gray-600">Purchased From:</span>
                          <span className="font-medium">{selectedAsset.purchased_from || '-'}</span>
                        </p>
                      </div>
                      <div className="space-y-3">
                        <p className="flex justify-between">
                          <span className="text-gray-600">Current Value:</span>
                          <span className="font-medium">₹{calculateDepreciationValue(selectedAsset)}</span>
                        </p>
                      </div>
                      {selectedAsset.purchase_invoice_url && (
                        <div className="col-span-2">
                          <a href={selectedAsset.purchase_invoice_url} 
                             target="_blank" 
                             rel="noopener noreferrer"
                             className="inline-flex items-center gap-2 text-blue-600 hover:text-blue-800">
                            <span>View Purchase Invoice</span>
                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                            </svg>
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* Maintenance History Timeline */}
                {selectedAsset.maintenance_history?.maintenance_history?.length > 0 && (
                  <div>
                    <Typography variant="subtitle1" sx={{ color: '#334155', fontWeight: 600, mb: 2 }}>
                      Maintenance History
                    </Typography>
                    <Timeline>
                      {(showAllMaintenance 
                        ? selectedAsset.maintenance_history.maintenance_history 
                        : selectedAsset.maintenance_history.maintenance_history.slice(0, INITIAL_RECORDS_TO_SHOW)
                      ).map((maintenance: any, index: number) => (
                        <TimelineItem key={index}>
                          <TimelineSeparator>
                            <TimelineDot sx={{ bgcolor: '#3b82f6' }} />
                            {index < (showAllMaintenance 
                              ? selectedAsset.maintenance_history.maintenance_history.length - 1
                              : Math.min(INITIAL_RECORDS_TO_SHOW - 1, selectedAsset.maintenance_history.maintenance_history.length - 1)
                            ) && (
                              <TimelineConnector sx={{ bgcolor: '#e2e8f0' }} />
                            )}
                          </TimelineSeparator>
                          <TimelineContent>
                            <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
                              <div className="flex justify-between items-center mb-3">
                                <span className="font-semibold text-gray-700">
                                  Maintenance Record #{selectedAsset.maintenance_history.maintenance_history.length - index}
                                </span>
                                <Chip
                                  label={maintenance.status || 'Completed'}
                                  color="success"
                                  size="small"
                                  sx={{ borderRadius: 1 }}
                                />
                              </div>
                              <div className="flex flex-col text-sm">
                                <p className="flex justify-between">
                                  <span className="text-gray-600">Date:</span>
                                  <span className="font-medium">{dayjs(maintenance.date).format('DD/MM/YYYY')}</span>
                                </p>
                                <p className="flex justify-between">
                                  <span className="text-gray-600">Amount:</span>
                                  <span className="font-medium">₹{maintenance.amount}</span>
                                </p>
                                {maintenance.notes && (
                                  <p className="col-span-2">
                                    <span className="text-gray-600">Notes:</span>
                                    <span className="font-medium ml-2">{maintenance.notes}</span>
                                  </p>
                                )}
                                {maintenance.invoice_url && (
                                  <p className="col-span-2">
                                    <a href={maintenance.invoice_url}
                                       target="_blank"
                                       rel="noopener noreferrer"
                                       className="inline-flex items-center gap-2 text-blue-600 hover:text-blue-800">
                                      <span>View Maintenance Invoice</span>
                                      <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                                      </svg>
                                    </a>
                                  </p>
                                )}
                              </div>
                            </div>
                          </TimelineContent>
                        </TimelineItem>
                      ))}
                    </Timeline>
                    
                    {selectedAsset.maintenance_history.maintenance_history.length > INITIAL_RECORDS_TO_SHOW && (
                      <div className="flex justify-center mt-4">
                        <Button
                          onClick={() => setShowAllMaintenance(!showAllMaintenance)}
                          variant="outlined"
                          color="primary"
                          startIcon={showAllMaintenance ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        >
                          {showAllMaintenance 
                            ? 'Show Less' 
                            : `View All (${selectedAsset.maintenance_history.maintenance_history.length - INITIAL_RECORDS_TO_SHOW} more)`
                          }
                        </Button>
                      </div>
                    )}
                  </div>
                )}
              </Box>
            </>
          )}
        </Box>
      </Modal>

      {/* Edit Modal */}
      <Modal
        open={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        aria-labelledby="edit-asset-modal"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          maxWidth: '800px',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          maxHeight: '90vh',
          overflowY: 'auto',
        }}>
          <AddAsset
            onClose={() => setIsEditModalOpen(false)}
            isEditMode={true}
            editData={selectedAsset}
          />
        </Box>
      </Modal>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this asset?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDelete} color="error">Delete</Button>
        </DialogActions>
      </Dialog>

      {/* History Modal */}
      <Modal
        open={isHistoryModalOpen}
        onClose={() => setIsHistoryModalOpen(false)}
        aria-labelledby="asset-history-modal"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          maxWidth: '1000px',
          maxHeight: '90vh',
          overflow: 'auto',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 2,
        }}>
          <Box sx={{ 
            position: 'sticky', 
            top: 0, 
            bgcolor: 'background.paper',
            borderBottom: '1px solid',
            borderColor: 'divider',
            p: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <Typography variant="h6">Asset History</Typography>
            <IconButton onClick={() => setIsHistoryModalOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ p: 2 }}>
            <AssetHistoryTimeline history={assetHistory} onClose={() => setIsHistoryModalOpen(false)} />
          </Box>
        </Box>
      </Modal>

      {/* Move to Appliance Dialog */}
      <Dialog
        open={isMoveToApplianceDialogOpen}
        onClose={() => setIsMoveToApplianceDialogOpen(false)}
        PaperProps={{
          sx: {
            width: '400px',
            maxWidth: '90%',
            borderRadius: 2,
          }
        }}
      >
        <DialogTitle sx={{ 
          bgcolor: 'primary.lighter', 
          color: 'primary.dark',
          py: 2
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <DevicesIcon />
            <Typography variant="h6">Move to Appliance</Typography>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography variant="body1">
              Are you sure you want to move <strong>{selectedAsset?.asset_name}</strong> to appliances?
            </Typography>
            <Typography variant="body2" color="text.secondary">
              This action will mark the asset as an appliance and may affect its management and maintenance schedules.
            </Typography>
            {/* Add any additional fields or information here */}
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 2, bgcolor: 'grey.50' }}>
          <Button 
            onClick={() => setIsMoveToApplianceDialogOpen(false)}
            variant="outlined"
            color="inherit"
          >
            Cancel
          </Button>
          <Button 
            onClick={confirmMoveToAppliance}
            variant="contained"
            color="primary"
            startIcon={<DevicesIcon />}
          >
            Move to Appliance
          </Button>
        </DialogActions>
      </Dialog>

      {/* Barcode Modal */}
      <NewModal 
        isOpen={isBarcodeModalOpen} 
        onClose={closeBarcodeModal} 
        title="Asset Barcode"
      >
        <div className="flex flex-col items-center justify-center">
          <div className='flex'>
            <img src="/company_logo.png" alt="Company Logo" width="140" height="140" />
            <div
              id="printableBarcode"
              className="flex items-center justify-center w-full h-full print:absolute print:inset-0"
            >
              {selectedAsset && (
                <Barcode
                  value={selectedAsset.asset_id}
                  width={2}
                  height={80}
                  displayValue={true}
                  format="CODE128"
                />
              )}
            </div>
          </div>
          <button
            onClick={() => {
              printBarcode();
              closeBarcodeModal();
            }}
            className="p-2 rounded-md bg-green-500 flex mt-4 p-2 text-white"
          >
            <FaPrint className="w-6 h-6" /> &nbsp; Print
          </button>
        </div>
      </NewModal>
    </div>
  );
};

export default AssetDashboard; 