import React, { useState, useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  Chip,
  Button
} from '@mui/material';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot
} from '@mui/lab';
import dayjs from 'dayjs';
import { PiBarcode } from "react-icons/pi";
import { FaPrint, FaFileDownload } from "react-icons/fa";
import Barcode from './Barcode';
import NewModal from '../common/newModal';
import { printBarcode } from '../../utils/common';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);

interface PunchTrail {
  punch_time: string;
  type: string;
}

interface AttendanceRecord {
  employeeId: string;
  employeeName: string;
  punchIn: string;
  punchOut: string;
  date: string;
  punchTrail: PunchTrail[];
}

interface EmployeeAttendanceTableProps {
  attendanceData: AttendanceRecord[];
}

const EmployeeAttendanceTable: React.FC<EmployeeAttendanceTableProps> = ({ attendanceData }) => {
  const [isBarcodeModalOpen, setIsBarcodeModalOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState<AttendanceRecord | null>(null);
  const [isPunchTrailOpen, setIsPunchTrailOpen] = useState(false);
  const [selectedPunchTrail, setSelectedPunchTrail] = useState<PunchTrail[]>([]);
  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(null);
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(null);

  // Filter attendance data based on date range
  const filteredAttendanceData = useMemo(() => {
    if (!startDate && !endDate) return attendanceData;

    return attendanceData.filter(record => {
      const recordDate = dayjs(record.date);
      if (startDate && endDate) {
        return recordDate.isBetween(startDate, endDate, 'day', '[]');
      } else if (startDate) {
        return recordDate.isAfter(startDate, 'day') || recordDate.isSame(startDate, 'day');
      } else if (endDate) {
        return recordDate.isBefore(endDate, 'day') || recordDate.isSame(endDate, 'day');
      }
      return true;
    });
  }, [attendanceData, startDate, endDate]);

  const handleBarcodeView = (employee: AttendanceRecord) => {
    setSelectedEmployee(employee);
    setIsBarcodeModalOpen(true);
  };

  const closeBarcodeModal = () => {
    setIsBarcodeModalOpen(false);
    setSelectedEmployee(null);
  };

  const handleRowClick = (record: AttendanceRecord) => {
    setSelectedPunchTrail(record.punchTrail);
    setIsPunchTrailOpen(true);
  };

  const downloadMSRRegister = () => {
    const createDynamicHeaders = (maxPairs: number) => {
      const baseHeaders = ['S. No.', 'Date', 'Name of Worker/Labour'];
      const punchHeaders = Array.from({ length: maxPairs }, (_, i) => [
        `Time In ${i + 1} (AM/PM)`,
        `Time out ${i + 1} (AM/PM)`
      ]).flat();
      return [...baseHeaders, ...punchHeaders].join(',');
    };

    // Find maximum number of IN/OUT pairs across all records
    const maxPairs = Math.max(...filteredAttendanceData.map(record => {
      const sortedPunches = [...record.punchTrail].sort((a, b) => 
        dayjs(a.punch_time).valueOf() - dayjs(b.punch_time).valueOf()
      );
      const inPunches = sortedPunches.filter(p => p.type.toLowerCase() === 'in');
      const outPunches = sortedPunches.filter(p => p.type.toLowerCase() === 'out');
      return Math.max(inPunches.length, outPunches.length);
    }));

    // Create headers based on maximum pairs
    const headers = createDynamicHeaders(maxPairs);

    // Process data for CSV
    const csvData = filteredAttendanceData.map((record, index) => {
      // Sort punch trail by time
      const sortedPunches = [...record.punchTrail].sort((a, b) => 
        dayjs(a.punch_time).valueOf() - dayjs(b.punch_time).valueOf()
      );

      // Get first punch-in time to determine the date
      const firstPunchIn = sortedPunches.find(p => p.type.toLowerCase() === 'in');

      // Get all IN and OUT punches
      const inPunches = sortedPunches
        .filter(p => p.type.toLowerCase() === 'in')
        .map(p => dayjs(p.punch_time).format('hh:mm A'));
      
      const outPunches = sortedPunches
        .filter(p => p.type.toLowerCase() === 'out')
        .map(p => dayjs(p.punch_time).format('hh:mm A'));

      // Create base row data using the date from first punch-in
      const baseData = [
        index + 1,
        firstPunchIn 
          ? dayjs(firstPunchIn.punch_time).format('DD/MM/YYYY')
          : dayjs(record.punchIn).format('DD/MM/YYYY'), // fallback to record.punchIn
        record.employeeName
      ];

      // Add punch pairs data
      const punchPairsData = Array.from({ length: maxPairs }, (_, i) => [
        inPunches[i] || '',
        outPunches[i] || ''
      ]).flat();

      return [...baseData, ...punchPairsData].join(',');
    });

    // Combine headers and data
    const csvContent = [headers, ...csvData].join('\n');

    // Create and trigger download with the date from the records
    const firstRecord = filteredAttendanceData[0];
    const firstPunch = firstRecord?.punchTrail?.find(p => p.type.toLowerCase() === 'in');
    const fileName = `msr_register.csv`;

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ 
        mb: 2, 
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <Typography variant="h6">
          Employee Attendance
        </Typography>
        
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <Button
            variant="outlined"
            startIcon={<FaFileDownload />}
            onClick={downloadMSRRegister}
            sx={{ mr: 2 }}
          >
            Download MSR Register
          </Button>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box sx={{ 
              display: 'flex', 
              gap: 2,
              alignItems: 'center',
              bgcolor: 'background.paper',
              p: 1,
              borderRadius: 1,
              boxShadow: 1
            }}>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                slotProps={{
                  textField: {
                    size: "small",
                    sx: { width: '150px' }
                  }
                }}
              />
              <Typography variant="body2" color="text.secondary">to</Typography>
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
                slotProps={{
                  textField: {
                    size: "small",
                    sx: { width: '150px' }
                  }
                }}
                minDate={startDate || undefined}
              />
              {(startDate || endDate) && (
                <Tooltip title="Clear Filters">
                  <IconButton 
                    size="small" 
                    onClick={() => {
                      setStartDate(null);
                      setEndDate(null);
                    }}
                    sx={{ ml: 1 }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </LocalizationProvider>
        </Box>
      </Box>

      <TableContainer component={Paper} sx={{ maxHeight: 'calc(100vh - 250px)' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f8fafc' }}>Employee Name</TableCell>
              <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f8fafc' }}>Date</TableCell>
              <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f8fafc' }}>Punch In</TableCell>
              <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f8fafc' }}>Punch Out</TableCell>
              <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f8fafc' }}>Total Hours</TableCell>
              <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f8fafc' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredAttendanceData.map((record, index) => {
              const punchIn = record.punchIn ? dayjs(record.punchIn) : null;
              const punchOut = record.punchOut ? dayjs(record.punchOut) : null;
              const totalHours = punchOut ? punchOut.diff(punchIn, 'hour', true).toFixed(2) : '-';

              return (
                <TableRow 
                  key={`${record.employeeId}-${index}`} 
                  hover
                  onClick={() => handleRowClick(record)}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell>{`${record.employeeName}`}</TableCell>
                  <TableCell>{dayjs(record.punchIn).format('YYYY-MM-DD')}</TableCell>
                  <TableCell>{punchIn ? punchIn.format('HH:mm') : '-'}</TableCell>
                  <TableCell>{punchOut ? punchOut.format('HH:mm') : '-'}</TableCell>
                  <TableCell>{totalHours}</TableCell>
                  <TableCell>
                    <Tooltip title="View Barcode">
                      <IconButton 
                        onClick={(e) => {
                          e.stopPropagation();
                          handleBarcodeView(record);
                        }} 
                        size="small"
                        sx={{
                          '&:hover': {
                            color: 'primary.main',
                            backgroundColor: 'primary.lighter',
                          }
                        }}
                      >
                        <PiBarcode className="w-5 h-5" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Barcode Modal */}
      <NewModal 
        isOpen={isBarcodeModalOpen} 
        onClose={closeBarcodeModal} 
        title="Employee Barcode"
      >
        <div className="flex flex-col items-center justify-center">
          <div className='flex'>
            <img src="/company_logo.png" alt="Company Logo" width="140" height="140" />
            <div
              id="printableBarcode"
              className="flex items-center justify-center w-full h-full print:absolute print:inset-0"
            >
              {selectedEmployee && (
                <Barcode
                  value={selectedEmployee.employeeId}
                  width={2}
                  height={80}
                  displayValue={true}
                  format="CODE128"
                />
              )}
            </div>
          </div>
          <button
            onClick={() => {
              printBarcode();
              closeBarcodeModal();
            }}
            className="p-2 rounded-md bg-green-500 flex mt-4 p-2 text-white"
          >
            <FaPrint className="w-6 h-6" /> &nbsp; Print
          </button>
        </div>
      </NewModal>

      {/* Punch Trail Modal */}
      <Dialog
        open={isPunchTrailOpen}
        onClose={() => setIsPunchTrailOpen(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            maxHeight: '80vh',
            m: 2,
            borderRadius: 2,
          }
        }}
      >
        <DialogTitle sx={{ 
          m: 0, 
          p: 2.5,
          bgcolor: 'primary.lighter',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid',
          borderColor: 'divider'
        }}>
          <Typography variant="h6" component="div" sx={{ fontWeight: 600 }}>
            Attendance Timeline
          </Typography>
          <IconButton
            onClick={() => setIsPunchTrailOpen(false)}
            size="small"
            sx={{ color: 'text.secondary' }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <Timeline sx={{ 
            [`& .MuiTimelineItem-root:before`]: {
              flex: 0,
            },
            my: -2
          }}>
            {selectedPunchTrail.map((punch, index) => (
              <TimelineItem key={index}>
                <TimelineSeparator>
                  <TimelineDot 
                    sx={{ 
                      bgcolor: punch.type.toLowerCase() === 'in' ? '#22c55e' : '#ef4444',
                      boxShadow: 1
                    }} 
                  />
                  {index < selectedPunchTrail.length - 1 && (
                    <TimelineConnector sx={{ 
                      bgcolor: '#e2e8f0',
                      height: 40
                    }} />
                  )}
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                  <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
                    <div className="flex justify-between items-center mb-2">
                      <Typography variant="subtitle2" sx={{ 
                        color: 'text.primary', 
                        fontWeight: 600,
                        fontSize: '0.9rem'
                      }}>
                        {dayjs(punch.punch_time).format('hh:mm A')}
                      </Typography>
                      <Chip
                        label={punch.type.toUpperCase()}
                        size="small"
                        color={punch.type.toLowerCase() === 'in' ? 'success' : 'error'}
                        sx={{ 
                          minWidth: 70,
                          fontWeight: 500
                        }}
                      />
                    </div>
                    <Typography variant="body2" sx={{ 
                      color: 'text.secondary',
                      fontSize: '0.8rem'
                    }}>
                      {dayjs(punch.punch_time).format('DD MMMM YYYY')}
                    </Typography>
                  </div>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default EmployeeAttendanceTable;