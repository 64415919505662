import React, { useState, useMemo } from 'react';
import {
  Box,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Grid,
  TextField,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  IconButton,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import * as XLSX from 'xlsx';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

interface ReportsProps {
  assetData: any[];
}

type DateRange = 'weekly' | 'monthly' | 'yearly' | 'custom';

// Row interface
interface RowProps {
  asset: any;
  expanded: boolean;
  onExpand: () => void;
}

// Table Row Component with expandable maintenance history
const AssetRow: React.FC<RowProps> = ({ asset, expanded, onExpand }) => (
  <>
    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
      <TableCell>
        {asset.maintenance_history?.maintenance_history?.length > 0 && (
          <IconButton size="small" onClick={onExpand}>
            {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        )}
      </TableCell>
      <TableCell>{asset.asset_id}</TableCell>
      <TableCell>{asset.asset_name}</TableCell>
      <TableCell>{asset.location} ({asset.location_category})</TableCell>
      <TableCell>
        <Box
          sx={{
            backgroundColor: 
              asset.status === 'active' ? 'success.light' :
              asset.status === 'inactive' ? 'error.light' :
              asset.status === 'under_maintenance' ? 'warning.light' :
              'info.light',
            borderRadius: 1,
            px: 1,
            py: 0.5,
            display: 'inline-block'
          }}
        >
          {asset.status?.replace(/_/g, ' ')}
        </Box>
      </TableCell>
      <TableCell>{dayjs(asset.purchase_date).format('YYYY-MM-DD')}</TableCell>
      <TableCell>₹{parseFloat(asset.purchase_cost).toLocaleString()}</TableCell>
      <TableCell>{asset.departments?.map((dept: { department_name: any; }) => dept.department_name).join(', ')}</TableCell>
    </TableRow>
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1 }}>
            <Typography variant="h6" gutterBottom component="div">
              Maintenance History
            </Typography>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Maintenance No</TableCell>
                  <TableCell>Notes</TableCell>
                  <TableCell>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {asset.maintenance_history?.maintenance_history?.length > 0 ? (
                  asset.maintenance_history.maintenance_history.map((record: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell>{dayjs(record.date).format('YYYY-MM-DD')}</TableCell>
                      <TableCell>{record.maintenance_no}</TableCell>
                      <TableCell>{record.notes}</TableCell>
                      <TableCell>₹{record.amount?.toLocaleString()}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No maintenance history available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  </>
);

const Reports: React.FC<ReportsProps> = ({ assetData }) => {
  const [dateRange, setDateRange] = useState<DateRange>('monthly');
  const [status, setStatus] = useState<string>('all');
  const [location, setLocation] = useState<string>('all');
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs().subtract(1, 'month'));
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());
  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});

  // Get unique locations from asset data
  const locations = useMemo(() => {
    const uniqueLocations = new Set(assetData.map(asset => asset.location));
    return Array.from(uniqueLocations);
  }, [assetData]);

  // Filter assets based on selected criteria
  const filteredAssets = useMemo(() => {
    return assetData.filter(asset => {
      const assetDate = dayjs(asset.purchase_date);
      const matchesDateRange = (() => {
        switch (dateRange) {
          case 'weekly':
            return assetDate.isAfter(dayjs().subtract(1, 'week'));
          case 'monthly':
            return assetDate.isAfter(dayjs().subtract(1, 'month'));
          case 'yearly':
            return assetDate.isAfter(dayjs().subtract(1, 'year'));
          case 'custom':
            return assetDate.isAfter(startDate) && assetDate.isBefore(endDate);
          default:
            return true;
        }
      })();

      const matchesStatus = status === 'all' || asset.status.toLowerCase() === status.toLowerCase();
      const matchesLocation = location === 'all' || asset.location === location;

      return matchesDateRange && matchesStatus && matchesLocation;
    });
  }, [assetData, dateRange, status, location, startDate, endDate]);

  // Export to Excel
  const exportToExcel = () => {
    const exportData = filteredAssets.map(asset => {
      const baseData = {
        'Asset ID': asset.asset_id,
        'Asset Name': asset.asset_name,
        'Description': asset.description,
        'Status': asset.status?.replace(/_/g, ' '),
        'Location': asset.location,
        'Location Category': asset.location_category,
        'Manufacturer': asset.manufacturer,
        'Serial Number': asset.serial_number,
        'Model': asset.model,
        'Purchase Date': dayjs(asset.purchase_date).format('YYYY-MM-DD'),
        'Purchase Cost': `₹${parseFloat(asset.purchase_cost).toLocaleString()}`,
        'Depreciation Rate': `${asset.depreciation_rate}%`,
        'Asset Category': asset.asset_category,
        'Asset Type': asset.asset_type,
        'Ownership': asset.ownership_type,
        'Purchased From': asset.purchased_from,
        'Departments': asset.departments?.map((dept: { department_name: any; }) => dept.department_name).join(', '),
        'Projects': asset.departments?.map((dept: { project_name: any; }) => dept.project_name).join(', '),
      };

      // If asset has maintenance history, create additional rows
      if (asset.maintenance_history?.maintenance_history?.length > 0) {
        return asset.maintenance_history.maintenance_history.map((record: any, index: number) => ({
          ...baseData,
          'Maintenance Date': dayjs(record.date).format('YYYY-MM-DD'),
          'Maintenance Number': record.maintenance_no,
          'Maintenance Notes': record.notes,
          'Maintenance Amount': `₹${record.amount?.toLocaleString()}`,
          'Row Type': index === 0 ? 'Primary' : 'Maintenance Record'
        }));
      }

      return { ...baseData, 'Row Type': 'Primary' };
    }).flat();

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Assets Report");
    XLSX.writeFile(wb, `Assets_Report_${dayjs().format('YYYY-MM-DD')}.xlsx`);
  };

  // Export to PDF
  const exportToPDF = () => {
    const doc = new jsPDF();
    
    // Title and metadata
    doc.setFontSize(16);
    doc.text('Asset Report', 14, 15);
    doc.setFontSize(10);
    doc.text(`Generated on: ${dayjs().format('YYYY-MM-DD')}`, 14, 25);
    doc.text(`Total Assets: ${filteredAssets.length}`, 14, 30);

    // Status summary
    const statusCounts = filteredAssets.reduce((acc: any, asset: any) => {
      const status = asset.status?.replace(/_/g, ' ') || 'Unknown';
      acc[status] = (acc[status] || 0) + 1;
      return acc;
    }, {});

    // Category summary
    const categoryCounts = filteredAssets.reduce((acc: any, asset: any) => {
      acc[asset.asset_category] = (acc[asset.asset_category] || 0) + 1;
      return acc;
    }, {});

    doc.setFontSize(12);
    doc.text('Status Summary:', 14, 40);
    let yPos = 45;
    Object.entries(statusCounts).forEach(([status, count]) => {
      doc.text(`${status}: ${count}`, 20, yPos);
      yPos += 5;
    });

    doc.text('Category Summary:', 14, yPos + 5);
    yPos += 10;
    Object.entries(categoryCounts).forEach(([category, count]) => {
      doc.text(`${category}: ${count}`, 20, yPos);
      yPos += 5;
    });

    // Main assets table
    const tableData = filteredAssets.map(asset => [
      asset.asset_id,
      asset.asset_name,
      asset.location,
      asset.status?.replace(/_/g, ' '),
      dayjs(asset.purchase_date).format('YYYY-MM-DD'),
      `₹${parseFloat(asset.purchase_cost).toLocaleString()}`,
      asset.asset_category,
      asset.departments?.map((dept: { department_name: any; }) => dept.department_name).join(', ')
    ]);

    (doc as any).autoTable({
      head: [['ID', 'Name', 'Location', 'Status', 'Purchase Date', 'Cost', 'Category', 'Department']],
      body: tableData,
      startY: yPos + 10,
      styles: { fontSize: 8 },
      headStyles: { fillColor: [41, 128, 185] },
    });

    // Maintenance summary on new page
    const assetsWithMaintenance = filteredAssets.filter(
      asset => asset.maintenance_history?.maintenance_history?.length > 0
    );
    
    if (assetsWithMaintenance.length) {
      doc.addPage();
      doc.setFontSize(14);
      doc.text('Maintenance Summary', 14, 15);

      const maintenanceData = assetsWithMaintenance.map(asset => 
        asset.maintenance_history.maintenance_history.map((record: any) => [
          asset.asset_id,
          asset.asset_name,
          dayjs(record.date).format('YYYY-MM-DD'),
          record.maintenance_no,
          record.notes?.substring(0, 30) + (record.notes?.length > 30 ? '...' : ''),
          `₹${record.amount?.toLocaleString()}`
        ])
      ).flat();

      (doc as any).autoTable({
        head: [['Asset ID', 'Asset Name', 'Date', 'Maintenance No', 'Notes', 'Amount']],
        body: maintenanceData,
        startY: 25,
        styles: { fontSize: 8 },
        headStyles: { fillColor: [41, 128, 185] },
      });
    }

    doc.save(`Assets_Report_${dayjs().format('YYYY-MM-DD')}.pdf`);
  };

  const handleRowExpand = (assetId: string) => {
    setExpandedRows(prev => ({
      ...prev,
      [assetId]: !prev[assetId],
    }));
  };

  return (
    <Box sx={{ p: 3 }}>
      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" sx={{ mb: 3 }}>
          Report Filters
        </Typography>
        
        <Grid container spacing={3}>
          {/* Date Range Filter */}
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel>Date Range</InputLabel>
              <Select
                value={dateRange}
                label="Date Range"
                onChange={(e) => setDateRange(e.target.value as DateRange)}
              >
                <MenuItem value="weekly">Last Week</MenuItem>
                <MenuItem value="monthly">Last Month</MenuItem>
                <MenuItem value="yearly">Last Year</MenuItem>
                <MenuItem value="custom">Custom Range</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Custom Date Range */}
          {dateRange === 'custom' && (
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack direction="row" spacing={2}>
                  <DatePicker
                    label="Start Date"
                    value={startDate}
                    onChange={(newValue) => setStartDate(newValue)}
                  />
                  <DatePicker
                    label="End Date"
                    value={endDate}
                    onChange={(newValue) => setEndDate(newValue)}
                  />
                </Stack>
              </LocalizationProvider>
            </Grid>
          )}

          {/* Status Filter */}
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                value={status}
                label="Status"
                onChange={(e) => setStatus(e.target.value)}
              >
                <MenuItem value="all">All Statuses</MenuItem>
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
                <MenuItem value="under_maintenance">Under Maintenance</MenuItem>
                <MenuItem value="damaged">Damaged</MenuItem>
                <MenuItem value="replaced">Replaced</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Location Filter */}
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel>Location</InputLabel>
              <Select
                value={location}
                label="Location"
                onChange={(e) => setLocation(e.target.value)}
              >
                <MenuItem value="all">All Locations</MenuItem>
                {locations.map((loc) => (
                  <MenuItem key={loc} value={loc}>{loc}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {/* Export Buttons */}
        <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
          <Button
            variant="contained"
            startIcon={<FileDownloadIcon />}
            onClick={exportToExcel}
          >
            Export to Excel
          </Button>
          <Button
            variant="contained"
            startIcon={<PictureAsPdfIcon />}
            onClick={exportToPDF}
          >
            Export to PDF
          </Button>
        </Box>
      </Paper>

      {/* Preview Table */}
      <Paper sx={{ p: 3 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Report Preview ({filteredAssets.length} assets)
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Asset ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Purchase Date</TableCell>
                <TableCell>Purchase Cost</TableCell>
                <TableCell>Department</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredAssets.map((asset) => (
                <AssetRow
                  key={asset.asset_id}
                  asset={asset}
                  expanded={expandedRows[asset.asset_id] || false}
                  onExpand={() => handleRowExpand(asset.asset_id)}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default Reports; 