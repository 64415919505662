import { Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import About from './pages/About';
import { Provider } from 'react-redux';
import { store, persistor } from './store/store/store';
import { PersistGate } from 'redux-persist/integration/react';
import './index.css';
import { Register } from './pages/Register';
import { Login } from './pages/Login';
import InspectionTasks from './pages/inspection/InspectionTasks';
import Appliances from './pages/inspection/Appliances';
import MainLayout from './components/layout/MainLayout';
import Tags from './pages/inspection/Tags';
import Checklist from './pages/inspection/Checklist';
import ProtectedRoute from './components/app/ProtectedRoute'; // Import the ProtectedRoute
import Profile from './pages/Profile';
import { InspectionDashboard } from './pages/inspection/InspectionDashboard';
import TeamsDashboard from './pages/inspection/TeamsDashboard';
import Reports from './pages/inspection/Reports';
import IssueManagementDashboard from './pages/inspection/IssueManagementDashboard';
import Alert from './pages/inspection/Alert';
import Construction from './pages/construction';
import ApprovalPage from './pages/inspection/ApprovalPage';
import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Statistics from './pages/inspection/statistics';
import { ChangePassword } from './pages/ChangePassword';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import { fetchNewRefreshToken } from './api/endpoints/APIEndpoints';
import Document from './pages/Document';
import AssetManagement from './pages/AssetManagement';
import InspectionLayout from './components/layout/InspectionLayout'; // We'll create this
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme/theme';  // Import the theme

function App() {

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const refreshToken = localStorage.getItem("refresh_token");
  //     if (refreshToken) {
  //       // Call your refresh token API
  //       fetchNewRefreshToken.post({ token: refreshToken })
  //         .then(response => {
  //           localStorage.setItem("auth_token", response.accessToken);
  //         })
  //         .catch(err => {
  //           console.error("Failed to refresh token:", err);
  //           // Handle logout if needed
  //         });
  //     }
  //   }, 50 * 60 * 1000); // Refresh every 10 minutes

  //   return () => clearInterval(interval);
  // }, []);



  return (
    <ThemeProvider theme={theme}>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={3}
      />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Routes>
            <Route element={<ProtectedRoute />}>
              <Route element={<MainLayout />}>
                <Route path="/" element={<Navigate to="/inspection" />} />
                
                {/* Inspection Routes */}
                <Route path="/inspection" element={<InspectionLayout />}>
                  <Route index element={<Home />} />
                  <Route path="tasks" element={<InspectionTasks />} />
                  <Route path="appliances" element={<Appliances />} />
                  <Route path="tags" element={<Tags />} />
                  <Route path="checklist" element={<Checklist />} />
                  <Route path="routine" element={<InspectionDashboard />} />
                  <Route path="teams" element={<TeamsDashboard />} />
                  <Route path="statistics" element={<Statistics />} />
                  <Route path="reports" element={<Reports />} />
                  <Route path="report-issue" element={<IssueManagementDashboard />} />
                  <Route path="alerts" element={<Alert />} />
                  <Route path="approval" element={<ApprovalPage />} />
                </Route>

                {/* Other main routes */}
                <Route path="/asset-management" element={<AssetManagement />} />
                <Route path="/documents" element={<Document />} />
                <Route path="/construction" element={<Construction />} />
                {/* <Route path="/process" element={<Process />} /> */}
                <Route path="/about" element={<About />} />
                <Route path="/profile" element={<Profile />} />
              </Route>
            </Route>
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
          </Routes>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
