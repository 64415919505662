import React, { useState, useEffect } from 'react';
import {
    Paper,
    Typography,
    Autocomplete,
    TextField,
    Grid,
    Button,
    Box
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { selectUserData } from '../../../store/store/authSlice';
import { reportAssetIssue, getAllDepartments } from '../../../api/endpoints/APIEndpoints';
import { toast } from 'react-toastify';
import Multiselect from 'multiselect-react-dropdown';
import { Select } from '../../common/Select';
import imageCompression from 'browser-image-compression';
import { convertToBase64 } from '../../../utils/common';

interface ReportAssetIssueProps {
    assetData: any[];
}

interface FormData {
    issue_type: string;
    priority: string;
    description: string;
    raised_by_department_id: string;
    target_department_ids: any[];
    photographicEvidence: File | null;
}

const ReportAssetIssues: React.FC<ReportAssetIssueProps> = ({ assetData }) => {
    const [selectedAsset, setSelectedAsset] = useState<any>(null);
    const [departments, setDepartments] = useState<any[]>([]);
    const [imageBase64, setImageBase64] = useState<string | null>(null);
    const userDetails = useSelector(selectUserData);
    
    const { control, handleSubmit, reset, setValue } = useForm<FormData>({
        defaultValues: {
            issue_type: '',
            priority: '',
            description: '',
            raised_by_department_id: '',
            target_department_ids: [],
            photographicEvidence: null
        }
    });

    // Fetch departments
    useEffect(() => {
        getAllDepartments.get()
            .then((res) => {
                const formattedDepts = res.map((dept: any) => ({
                    value: dept.department_id,
                    label: dept.department_name
                }));
                setDepartments(formattedDepts);
            })
            .catch(err => console.error("Error fetching departments:", err));
    }, []);

    // Add image compression function
    const compressImage = async (file: File): Promise<File> => {
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        };

        try {
            return await imageCompression(file, options);
        } catch (error) {
            console.error("Error compressing image:", error);
            return file;
        }
    };

    // Add file change handler
    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const compressedFile = await compressImage(file);
            setValue('photographicEvidence', compressedFile);
            const base64 = await convertToBase64(compressedFile);
            setImageBase64(base64);
        }
    };

    const onSubmit = (formData: FormData) => {
        const payload = {
            asset_id: selectedAsset?.asset_id,
            issue_type: formData.issue_type,
            priority: formData.priority,
            description: formData.description,
            raised_by_department_id: Number(formData.raised_by_department_id),
            target_department_ids: formData.target_department_ids.map((dept: any) => dept.id),
            reported_by: userDetails?.employee_id,
            photoEvidence: imageBase64
        };

        reportAssetIssue.post(payload)
            .then(() => {
                toast.success('Issue reported successfully');
                setSelectedAsset(null);
                reset();
            })
            .catch((err) => {
                toast.error('Failed to report issue');
                console.error(err);
            });
    };

    const multiSelectFormattedDepartmentOptions = departments.map(item => ({
        name: item.label,
        id: item.value
    }));

    return (
        <Paper className="p-8 max-w-3xl mx-auto mt-8">
            <Typography variant="h5" className="mb-6 text-gray-800 font-medium">
                Report Asset Issue
            </Typography>

            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Autocomplete
                            options={assetData || []}
                            getOptionLabel={(option) =>
                                `${option.asset_name} - ${option.asset_id}`
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Asset"
                                    required
                                />
                            )}
                            value={selectedAsset}
                            onChange={(_, newValue) => setSelectedAsset(newValue)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Controller
                            name="issue_type"
                            control={control}
                            rules={{ required: 'Issue Type is required' }}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    label="Issue Type"
                                    options={[
                                        { value: 'hardware', label: 'Hardware' },
                                        { value: 'software', label: 'Software' },
                                        { value: 'network', label: 'Network' },
                                        { value: 'other', label: 'Other' }
                                    ]}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Controller
                            name="priority"
                            control={control}
                            rules={{ required: 'Priority is required' }}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    label="Priority"
                                    options={[
                                        { value: 'low', label: 'Low' },
                                        { value: 'medium', label: 'Medium' },
                                        { value: 'high', label: 'High' }
                                    ]}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Controller
                            name="description"
                            control={control}
                            rules={{ required: 'Description is required' }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    fullWidth
                                    label="Issue Description"
                                    required
                                    multiline
                                    rows={4}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Controller
                            name="raised_by_department_id"
                            control={control}
                            rules={{ required: 'From Department is required' }}
                            render={({ field }) => (
                                <Select
                                    label="From Department"
                                    options={departments}
                                    {...field}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <p className='text-left text-sm pb-2 text-gray-800'>Target Department(s)</p>
                        <Controller
                            name="target_department_ids"
                            control={control}
                            render={({ field }) => (
                                <Multiselect
                                    options={multiSelectFormattedDepartmentOptions}
                                    selectedValues={field.value}
                                    onSelect={(selectedList) => field.onChange(selectedList)}
                                    onRemove={(selectedList) => field.onChange(selectedList)}
                                    displayValue="name"
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <div className="mb-4">
                            <label className="text-left block text-sm text-gray-700 cursor-pointer">
                                Photographic Evidence
                            </label>
                            <input
                                type="file"
                                onChange={handleFileChange}
                                accept="image/*"
                                className="mt-1 block w-full text-sm text-gray-500
                                file:mr-4 file:py-2 file:px-4
                                file:rounded-full file:border-0
                                file:text-sm file:font-semibold
                                file:bg-blue-50 file:text-blue-700
                                hover:file:bg-blue-100"
                            />
                        </div>
                    </Grid>
                </Grid>

                <Box className="mt-8 flex justify-end">
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                    >
                        Report Issue
                    </Button>
                </Box>
            </form>
        </Paper>
    );
};

export default ReportAssetIssues;
