import { Components, createTheme, CssVarsTheme, Theme } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';

// Define breakpoints
const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
};

// Define spacing unit (in px)
const spacingUnit = 6;

// Define color palette
const colors = {
  primary: {
    main: '#1976d2',
    light: '#42a5f5',
    dark: '#1565c0',
    contrastText: '#fff',
  },
  secondary: {
    main: '#9c27b0',
    light: '#ba68c8',
    dark: '#7b1fa2',
    contrastText: '#fff',
  },
  error: {
    main: '#d32f2f',
    light: '#ef5350',
    dark: '#c62828',
  },
  warning: {
    main: '#ed6c02',
    light: '#ff9800',
    dark: '#e65100',
  },
  info: {
    main: '#0288d1',
    light: '#03a9f4',
    dark: '#01579b',
  },
  success: {
    main: '#2e7d32',
    light: '#4caf50',
    dark: '#1b5e20',
  },
  grey: {
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#eeeeee',
    300: '#e0e0e0',
    400: '#bdbdbd',
    500: '#9e9e9e',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
  },
  background: {
    default: '#ffffff',
    paper: '#ffffff',
  },
  text: {
    primary: 'rgba(0, 0, 0, 0.87)',
    secondary: 'rgba(0, 0, 0, 0.6)',
    disabled: 'rgba(0, 0, 0, 0.38)',
  },
};

// Define typography scale
const typography = {
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: 12,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  h1: {
    fontSize: '1.875rem',
    fontWeight: 500,
    lineHeight: 1.2,
    letterSpacing: '-0.01562em',
  },
  h2: {
    fontSize: '1.5rem',
    fontWeight: 500,
    lineHeight: 1.2,
    letterSpacing: '-0.00833em',
  },
  h3: {
    fontSize: '1.3125rem',
    fontWeight: 500,
    lineHeight: 1.2,
    letterSpacing: '0em',
  },
  h4: {
    fontSize: '1.125rem',
    fontWeight: 500,
    lineHeight: 1.2,
    letterSpacing: '0.00735em',
  },
  h5: {
    fontSize: '0.9375rem',
    fontWeight: 500,
    lineHeight: 1.2,
    letterSpacing: '0em',
  },
  h6: {
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: 1.2,
    letterSpacing: '0.0075em',
  },
  subtitle1: {
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: 1.75,
    letterSpacing: '0.00938em',
  },
  subtitle2: {
    fontSize: '0.75rem',
    fontWeight: 500,
    lineHeight: 1.57,
    letterSpacing: '0.00714em',
  },
  body1: {
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: '0.00938em',
  },
  body2: {
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: 1.43,
    letterSpacing: '0.01071em',
  },
  button: {
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: 1.75,
    letterSpacing: '0.02857em',
    textTransform: 'uppercase',
  },
  caption: {
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: 1.66,
    letterSpacing: '0.03333em',
  },
};

// Define component overrides
const components = {
  MuiPaper: {
    styleOverrides: {
      root: {
        borderRadius: 6,
        padding: spacingUnit * 2,
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        padding: '6px 16px',
        fontSize: '0.75rem',
        borderRadius: 4,
      },
      sizeLarge: {
        padding: '8px 22px',
        fontSize: '0.8125rem',
      },
      sizeSmall: {
        padding: '4px 10px',
        fontSize: '0.7rem',
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: 8,
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        padding: '8px 12px',
        fontSize: '0.75rem',
      },
      head: {
        fontSize: '0.75rem',
        fontWeight: 500,
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        height: '24px',
        fontSize: '0.75rem',
      },
      sizeSmall: {
        height: '20px',
      },
    },
  },
  // Common chart sizes
  charts: {
    small: {
      height: 188,
      padding: spacingUnit * 2,
    },
    medium: {
      height: 263,
      padding: spacingUnit * 3,
    },
    large: {
      height: 338,
      padding: spacingUnit * 4,
    },
  },
};

// Create and export theme
export const theme = createTheme({
  breakpoints,
  palette: colors,
  typography: typography as TypographyOptions,
  components: components as Components<Omit<Theme, "components" | "palette"> & CssVarsTheme>,
  spacing: spacingUnit,
  shape: {
    borderRadius: 4,
  },
  mixins: {
    toolbar: {
      minHeight: 56,
      '@media (min-width:0px) and (orientation: landscape)': {
        minHeight: 48,
      },
      '@media (min-width:600px)': {
        minHeight: 64,
      },
    },
  },
});

// Export common sizes and styles for reuse
export const commonStyles = {
  cardSizes: {
    small: {
      height: 225,
      width: '100%',
    },
    medium: {
      height: 300,
      width: '100%',
    },
    large: {
      height: 375,
      width: '100%',
    },
  },
  chartSizes: {
    pie: {
      small: { height: 150, innerRadius: 30, outerRadius: 60 },
      medium: { height: 225, innerRadius: 45, outerRadius: 90 },
      large: { height: 300, innerRadius: 60, outerRadius: 120 },
    },
    bar: {
      small: { height: 150 },
      medium: { height: 225 },
      large: { height: 300 },
    },
    line: {
      small: { height: 150 },
      medium: { height: 225 },
      large: { height: 300 },
    },
  },
  spacing: {
    page: spacingUnit * 3,
    section: spacingUnit * 2,
    element: spacingUnit,
  },
}; 