import React, { useState, useCallback, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Modal,
  IconButton,
  Tabs,
  Tab,
  Tooltip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Search, Close as CloseIcon, ZoomIn as ZoomInIcon, ImageNotSupported as ImageNotSupportedIcon, Image as ImageIcon } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { selectUserData } from '../../../store/store/authSlice';
import { fetchAssetIssues } from '../../../api/endpoints/APIEndpoints';
import Loader from '../../common/Loader';
import ReportAssetIssues from './ReportAssetIssues';
import ResolveAssetIssue from './ResolveAssetIssue';
import NewModal from '../../common/newModal';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 600,
  backgroundColor: theme.palette.grey[100],
}));

const StyledTableHead = styled(TableHead)({
  '& th': {
    fontWeight: 600,
  },
});

const getStatusColor = (status: string) => {
  switch (status.toLowerCase()) {
    case 'resolved': return 'success';
    case 'pending': return 'warning';
    case 'in progress': return 'info';
    default: return 'default';
  }
};

const getPriorityColor = (priority: string) => {
  switch (priority.toLowerCase()) {
    case 'high': return 'error';
    case 'medium': return 'warning';
    case 'low': return 'success';
    default: return 'default';
  }
};

interface AssetIssuesProps {
  assetData: any;
}

const AssetIssues: React.FC<AssetIssuesProps> = ({ assetData }) => {
  const [tabValue, setTabValue] = useState(0);
  const [issues, setIssues] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [selectedIssue, setSelectedIssue] = useState<any | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [photoModalOpen, setPhotoModalOpen] = useState(false);
  const userDetails = useSelector(selectUserData);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const filteredIssues = issues.filter((issue) => {
    if (!issue) return false;
    const searchLower = searchTerm.toLowerCase();
    return (
      (issue.name?.toLowerCase().includes(searchLower) ?? false) ||
      (issue.type?.toLowerCase().includes(searchLower) ?? false) ||
      (issue.status?.toLowerCase().includes(searchLower) ?? false)
    );
  });

  const fetchIssues = useCallback(() => {
    return fetchAssetIssues.post({ employee_id: userDetails?.employee_id })
      .then((res) => {
        setIssues(Array.isArray(res.issues) ? res.issues : []);
      })
      .catch((error) => {
        console.log("ERROR!!", error);
        setIssues([]);
      })
      .finally(() => setIsLoading(false));
  }, [userDetails?.employee_id]);

  useEffect(() => {
    fetchIssues();
  }, [fetchIssues]);

  const handleIssueClick = (issue: any) => {
    setSelectedIssue(issue);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedIssue(null);
  };

  if (isLoading) return <Loader />;

  const renderContent = () => {
    switch (tabValue) {
      case 0:
        return (
          <>
            {/* Search Bar */}
            <div className="relative mb-4">
              <input
                type="text"
                placeholder="Search issues..."
                className="pl-10 pr-4 py-2 border rounded-lg w-full"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <Search className="absolute left-3 top-2.5 text-gray-400" />
            </div>

            {/* Issues Table */}
            <TableContainer component={Paper}>
              <Table>
                <StyledTableHead>
                  <TableRow>
                    <StyledTableCell>Issue ID</StyledTableCell>
                    <StyledTableCell>Asset ID</StyledTableCell>
                    <StyledTableCell>Description</StyledTableCell>
                    <StyledTableCell>Department</StyledTableCell>
                    <StyledTableCell>Type</StyledTableCell>
                    <StyledTableCell>Raised Date</StyledTableCell>
                    <StyledTableCell>Resolved Date</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                    <StyledTableCell>Priority</StyledTableCell>
                    <StyledTableCell>Location</StyledTableCell>
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  {filteredIssues.map((issue: any) => (
                    <TableRow key={issue.issue_id}>
                      <TableCell
                        onClick={() => handleIssueClick(issue)}
                        style={{ cursor: 'pointer', color: 'blue' }}
                      >
                        {issue.issue_id}
                      </TableCell>
                      <TableCell>{issue.asset_id}</TableCell>
                      <TableCell>{issue.description}</TableCell>
                      <TableCell>{issue.Department.department_name}</TableCell>
                      <TableCell>{issue.type}</TableCell>
                      <TableCell>{issue.raised_date}</TableCell>
                      <TableCell>{issue.resolved_date || "--"}</TableCell>
                      <TableCell>
                        <Chip 
                          label={issue.status}
                          color={getStatusColor(issue.status)}
                        />
                      </TableCell>
                      <TableCell>
                        <Chip 
                          label={issue.priority}
                          color={getPriorityColor(issue.priority)}
                        />
                      </TableCell>
                      <TableCell>{issue.location}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        );
      case 1:
        return <ReportAssetIssues assetData={assetData} />;
      case 2:
        return <ResolveAssetIssue onSuccess={fetchIssues} issues={issues} />;
      default:
        return null;
    }
  };

  return (
    <Box>
      <Tabs value={tabValue} onChange={handleTabChange}>
        <Tab label="Dashboard" />
        <Tab label="Report Issue" />
        <Tab label="Resolve Issue" />
      </Tabs>

      <Box sx={{ mt: 3 }}>
        {renderContent()}
      </Box>

      {/* Issue Details Modal */}
      <NewModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="issue-details-modal"
        title='Issue Details'
      >
        {selectedIssue && (
          <div className="p-6">
            {/* Main Content */}
            <div className="space-y-6">
              {/* Basic Information Card */}
              <div className="bg-white rounded-lg border border-gray-200 overflow-hidden">
                <div className="bg-gray-50 px-4 py-3 border-b border-gray-200">
                  <h3 className="text-lg font-medium text-gray-900">Basic Information</h3>
                </div>
                <div className="px-4 py-3">
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <p className="text-sm text-gray-500">Issue ID</p>
                      <p className="mt-1 font-medium">{selectedIssue.issue_id}</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">Asset ID</p>
                      <p className="mt-1 font-medium">{selectedIssue.asset_id}</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">Type</p>
                      <p className="mt-1 font-medium capitalize">{selectedIssue.type}</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">Location</p>
                      <p className="mt-1 font-medium">{selectedIssue.location}</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Status Details Card */}
              <div className="bg-white rounded-lg border border-gray-200 overflow-hidden">
                <div className="bg-gray-50 px-4 py-3 border-b border-gray-200">
                  <h3 className="text-lg font-medium text-gray-900">Status Details</h3>
                </div>
                <div className="px-4 py-3">
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <p className="text-sm text-gray-500">Status</p>
                      <div className="mt-1">
                        <Chip 
                          label={selectedIssue.status}
                          color={getStatusColor(selectedIssue.status)}
                          size="small"
                        />
                      </div>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">Priority</p>
                      <div className="mt-1">
                        <Chip 
                          label={selectedIssue.priority}
                          color={getPriorityColor(selectedIssue.priority)}
                          size="small"
                        />
                      </div>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">Raised Date</p>
                      <p className="mt-1 font-medium">{selectedIssue.raised_date}</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">Department</p>
                      <p className="mt-1 font-medium">{selectedIssue.Department?.department_name}</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Description Card */}
              {selectedIssue.description && (
                <div className="bg-white rounded-lg border border-gray-200 overflow-hidden">
                  <div className="bg-gray-50 px-4 py-3 border-b border-gray-200">
                    <h3 className="text-lg font-medium text-gray-900">Description</h3>
                  </div>
                  <div className="px-4 py-3">
                    <p className="text-gray-700 whitespace-pre-wrap">{selectedIssue.description}</p>
                  </div>
                </div>
              )}

              {/* Photo Evidence Link */}
              {selectedIssue.photo_evidence && (
                <div className="mt-4 flex items-center justify-end">
                  <button
                    onClick={() => setPhotoModalOpen(true)}
                    className="inline-flex items-center text-blue-600 hover:text-blue-700"
                  >
                    <ImageIcon className="w-5 h-5 mr-2" />
                    View Photo Evidence
                  </button>
                </div>
              )}
            </div>

            {/* Photo Modal */}
            <Modal
              open={photoModalOpen}
              onClose={() => setPhotoModalOpen(false)}
              aria-labelledby="photo-modal"
            >
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg shadow-xl max-w-2xl w-full">
                <div className="p-4 border-b border-gray-200 flex justify-between items-center">
                  <h2 className="text-lg font-medium">Photo Evidence</h2>
                  <IconButton onClick={() => setPhotoModalOpen(false)} size="small">
                    <CloseIcon />
                  </IconButton>
                </div>
                <div className="p-4">
                  <img
                    src={selectedIssue.photo_evidence}
                    alt="Issue Evidence"
                    className="w-full h-auto rounded-lg"
                  />
                </div>
              </div>
            </Modal>
          </div>
        )}
      </NewModal>
    </Box>
  );
};

export default AssetIssues;